var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"custom-sort":_vm.customSort,"sort-by":"fulltNavn","height":"calc(100vh - 370px)","fixed-header":true,"loading":_vm.loading,"loading-text":"Henter","no-data-text":"","items-per-page":-1,"show-select":"","must-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.profile",fn:function(ref){
var item = ref.item;
return [(item.avatar)?_c('v-avatar',{attrs:{"size":"40px"}},[_c('img',{attrs:{"alt":"Avatar","src":item.avatar}})]):_vm._e()]}},{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"v-input-data-item--checkbox",attrs:{"value":item.id,"hide-details":true},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}},{key:"item.fulltNavn",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"v-data-table-item--link",on:{"click":function($event){return _vm.edit(index)}}},[_c('a',{staticClass:"text-decoration-none"},[_vm._v(_vm._s(item.fulltNavn))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.statusNavn)+" ")])]}},{key:"item.tjeneste",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.tjeneste(item))+" ")])]}},{key:"item.sorgGrupper",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.sorgGrupper(item))+" ")])]}},{key:"item.oppstartsdato",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format")(item.oppstartsdato,'DD.MM.YYYY'))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }