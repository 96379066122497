<template>
    <div class="pt-6">
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <h1 class="text-h4">
                        {{ heading }}
                    </h1></v-col
                >
                <v-col cols="12">
                    <v-form ref="form" v-model="valid" lazy-validation style="max-width: 800px">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="person.fornavn" :rules="rules.fornavn" label="Fornavn" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="person.etternavn" :rules="rules.etternavn" label="Etternavn" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="person.epost" :rules="rules.email" label="E-postadresse"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6"> <v-text-field v-model="person.mobil" :rules="rules.mobil" label="Mobil" required></v-text-field> </v-col
                        ></v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select v-model="person.kjoenn" :items="kjoennValg" item-value="value" item-text="label" label="Kjønn"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <date-field v-model="person.foedselsdato" label="Fødselsdato"></date-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="person.adresseLinje1" :rules="rules.adresseLinje" label="Adresse"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="person.postnr" :rules="rules.postnr" label="Postnr."></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="person.poststed" :rules="rules.poststed" label="Sted"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select v-model="person.bydel" :items="bydelValg" label="Bydel"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="person.paarorende" :rules="rules.paarorende" label="Pårørende"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="5" class="d-flex align-center">
                                <date-field v-model="person.oppstartsdato" label="Oppstart"></date-field>
                            </v-col>
                        </v-row>
                        <br />
                        <v-btn color="primary" v-on:click="create">
                            {{ $t('btn.create') }}
                        </v-btn>
                        <v-btn text color="primary" v-on:click="cancel">Lukk</v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { /*mapGetters,*/ mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';

export default {
    name: 'NewPerson',
    components: {
        DateField,
    },
    props: {
        role: {
            type: String,
            required: true,
        },
        dialog: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            valid: true,

            person: {
                fornavn: '',
                etternavn: '',
                epost: '',
                mobil: '',
                adresseLinje1: '',
                postnr: '',
                poststed: '',
                bydel: '',
                paarorende: '',
                kjoenn: '',
                foedselsdato: null,
                oppstartsdato: null,
            },

            kjoennValg: [
                { value: 'MANN', label: 'Mann' },
                { value: 'KVINNE', label: 'Kvinne' },
                { value: 'ANNET', label: 'Annet' },
                { value: 'IKKE_OPPGITT', label: 'Ikke oppgitt' },
            ],

            bydelValg: [
                '',
                'Alna',
                'Bjerke',
                'Frogner',
                'Gamle Oslo',
                'Grorud',
                'Grünerløkka',
                'Nordre Aker',
                'Nordstrand',
                'Sagene',
                'St. Hanshaugen',
                'Stovner',
                'Søndre Nordstrand',
                'Ullern',
                'Vestre Aker',
                'Østensjø',
            ],

            rules: {
                fornavn: [(v) => !!v || 'Fornavn må oppgis', (v) => (v && v.length <= 255) || 'Fornavn må være mindre enn 255 tegn'],
                etternavn: [(v) => !!v || 'Etternavn må oppgis', (v) => (v && v.length <= 255) || 'Etternavn må være mindre enn 255 tegn'],
                email: [(v) => !v || /.+@.+\..+/.test(v) || 'E-postadressen er ikke gyldig'],
                mobil: [(v) => /^[\d\s]*$/.test(v) || 'Ikke et gyldig mobilnummer.'],
                adresseLinje: [(v) => (v && v.length > 255 ? 'Poststed må være mindre enn 255 tegn' : true)],
                postnr: [(v) => (v && !/^\d*$/.test(v) ? 'Må være kun tall' : true)],
                poststed: [(v) => (v && v.length > 255 ? 'Poststed må være mindre enn 255 tegn' : true)],
                bydel: [(v) => (v && v.length > 255 ? 'Bydel må være mindre enn 255 tegn' : true)],
                paarorende: [(v) => (v && v.length > 255 ? 'Pårørende må være mindre enn 255 tegn' : true)],
            },
        };
    },
    computed: {
        heading: function () {
            return this.$i18n.t('persons.' + this.role.toLowerCase() + '.new.heading');
        },
        newBtn: function () {
            return this.$i18n.t('persons.' + this.role.toLowerCase() + '.new.new');
        },
        newRoute: function () {
            return { path: '/personer/' + this.role.toLowerCase() + '/ny' };
        },
    },
    filters: {},

    created: function () {},
    methods: {
        ...mapActions('api', ['request']),
        ...mapActions(['alert', 'snackbar']),

        /**
         * create
         */
        create: async function () {
            if (this.$refs.form.validate()) {
                const roller = this.role.toLowerCase().split('_');
                let rolle = roller[0];

                const data = Object.assign({}, this.person);
                if (data.foedselsdato) {
                    data.foedselsdato = data.foedselsdato.format('DD.MM.YYYY');
                }
                delete data.oppstartsdato;

                if (!data.kjoenn) {
                    data.kjoenn = 'IKKE_OPPGITT';
                }

                let response = await this.request({
                    method: 'post',
                    url: '/person/' + rolle,
                    data: data,
                });

                if (response && typeof response.id != 'undefined') {
                    const person = response;
                    if (roller.length > 1) {
                        response = await this.request({
                            method: 'post',
                            url: '/person/' + person.id + '/rolle/' + this.role.toUpperCase(),
                        });
                    }

                    if (this.person.oppstartsdato) {
                        const data = {};
                        for (const field of ['fornavn', 'etternavn', 'kjoenn']) {
                            data[field] = this.person[field];
                        }
                        for (const field of ['foedselsdato', 'oppstartsdato', 'politiattest', 'soknadsdato', 'taushetserklaering']) {
                            data[field] = this.person[field] ? this.person[field].format('DD.MM.YYYY') : null;
                        }

                        await this.request({
                            method: 'post',
                            url: '/person/' + person.id + '/personalia',
                            data: data,
                        });
                    }

                    this.snackbar(this.$i18n.t('persons.' + this.role.toLowerCase() + '.new.created'));

                    if (this.dialog) {
                        this.reset();
                        this.$emit('close', person);
                    } else {
                        if (this.role == 'bruker_sorg') {
                            this.$router.push({ name: '/sorg/brukere/' });
                        } else if (this.role == 'frivillig') {
                            this.$router.push({ name: '/frivillige/' });
                        } else {
                            this.$router.push({ path: '/' });
                        }
                    }
                } else if (response && response.constructor.name == 'Error') {
                    const error = response.response.data.error;
                    if (error == 'PersonMedEpostFinnesAlleredeException') {
                        await this.alert({
                            title: 'Feil',
                            message: 'Det finnes allerede en annen person med ' + this.personalia.epost + ' som e-postadresse.',
                        });
                    } else {
                        await this.alert({ title: 'Feil', message: 'Det oppstod en ukjent feil.' });
                    }
                }
            }
        },

        /**
         * cancel
         */
        cancel: function () {
            if (this.dialog) {
                this.reset();
                this.$emit('close');
            }
        },

        reset: function () {
            this.form = {
                fornavn: '',
                etternavn: '',
                epost: '',
                mobil: '',
            };
            this.$refs.form.resetValidation();
        },
    },
};
</script>
<style lang="scss"></style>
