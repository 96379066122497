<template>
    <v-data-table
        v-model="selected"
        item-key="id"
        :headers="headers"
        :items="items"
        :custom-sort="customSort"
        sort-by="fulltNavn"
        height="calc(100vh - 370px)"
        :fixed-header="true"
        :loading="loading"
        loading-text="Henter"
        no-data-text=""
        :items-per-page="-1"
        show-select
        must-sort
        hide-default-footer
    >
        <template v-slot:item.profile="{ item }">
            <v-avatar size="40px" v-if="item.avatar">
                <img alt="Avatar" :src="item.avatar" />
            </v-avatar>
        </template>
        <template v-slot:item.select="{ item }">
            <v-checkbox class="v-input-data-item--checkbox" v-model="selected" :value="item.id" :hide-details="true"></v-checkbox>
        </template>
        <template v-slot:item.fulltNavn="{ item, index }">
            <td v-on:click="edit(index)" class="v-data-table-item--link">
                <a class="text-decoration-none">{{ item.fulltNavn }}</a>
            </td>
        </template>
        <template v-slot:item.status="{ item }">
            <span>
                {{ item.statusNavn }}
            </span>
        </template>
        <template v-slot:item.tjeneste="{ item }">
            <span>
                {{ tjeneste(item) }}
            </span>
        </template>
        <template v-slot:item.sorgGrupper="{ item }">
            <span>
                {{ sorgGrupper(item) }}
            </span>
        </template>
        <template v-slot:item.oppstartsdato="{ item }"> {{ item.oppstartsdato | format('DD.MM.YYYY') }} </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'PersonsDataTable',
    props: {
        value: {
            default: function () {
                return [];
            },
            type: Array,
        },
        personer: {
            type: Array,
            required: true,
        },
        view: {
            type: String,
            required: '',
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        const headers = [
            { value: 'fulltNavn', text: 'Navn' },
            { value: 'bydel', text: 'Bydel' },
            { value: 'status', text: 'Status', align: 'start' },
            { value: 'tjeneste', text: 'Tjeneste', align: 'start' },
            { value: 'sorgGrupper', text: 'Sorggruppe', align: 'start' },
            { value: 'oppstartsdato', text: 'Oppstart' },
            { value: 'lopenummer', text: 'Nr' },
        ];

        return {
            allHeaders: headers,
            headers: headers,

            allSelected: false,
            selected: [],
            items: [],
        };
    },
    watch: {
        personer: function (items) {
            this.items = items;
        },
        view: function (value) {
            this.view = value || '';
            this.filterHeaders();
        },
        selected: function (value) {
            this.$emit('input', value);
        },
    },
    filters: {
        format: function (value, format) {
            return value ? value.format(format) : '';
        },
    },
    created: function () {
        this.filterHeaders();
    },
    methods: {
        /**
         * filterHeaders
         */
        filterHeaders: function () {
            this.headers = this.allHeaders.filter((header) => {
                switch (header.value) {
                    case 'bydel':
                        return this.view.indexOf('HJEMME') > 0;

                    case 'tjeneste':
                        return this.view.endsWith('/');

                    case 'sorgGrupper':
                        return this.view.indexOf('SORG') > 0;

                    case 'lopenummer':
                        return this.view.indexOf('BRUKER') != -1;
                }
                return true;
            });
            this.$emit('fields', this.headers);
        },

        /**
         * tjeneste
         */
        tjeneste: function (person) {
            let tjeneste = '';
            if (person.roller) {
                const tjenester = [];
                for (const rolle of person.roller) {
                    switch (rolle.id) {
                        case 'FRIVILLIG_SORG':
                        case 'BRUKER_SORG':
                            tjenester.push('Sorgtjenesten');
                            break;

                        case 'FRIVILLIG_HJEMME':
                        case 'BRUKER_HJEMME':
                            tjenester.push('Hjemmetjeneste');
                            break;
                    }
                }
                tjeneste = tjenester.join(', ');
            }
            return tjeneste;
        },

        /**
         * sorgGrupper
         */
        sorgGrupper: function (person) {
            if (person.sorgGrupper) {
                const sorgGrupper = [];
                for (const sorgGruppe of person.sorgGrupper) {
                    sorgGrupper.push(sorgGruppe.tittel);
                }
                return sorgGrupper.join(', ');
            }
            return '';
        },

        /**
         * edit
         */
        edit: function (index) {
            this.$emit('edit', index);
        },

        /**
         * customSort
         */
        customSort: function (items, index, isDesc) {
            if (index.length) {
                items.sort((a, b) => {
                    switch (index[0]) {
                        case 'fulltNavn':
                            a = a.etternavn + ' ' + a.fornavn;
                            b = b.etternavn + ' ' + b.fornavn;
                            break;

                        case 'oppstartsdato':
                            a = a.oppstartsdato ? a.oppstartsdato : 0;
                            b = b.oppstartsdato ? b.oppstartsdato : 0;
                            return isDesc[0] ? a - b : b - a;

                        case 'bydel':
                            a = a.bydel || '';
                            b = b.bydel || '';
                            break;

                        case 'status':
                            a = a.statusNavn;
                            b = b.statusNavn;
                            break;

                        case 'tjeneste':
                            a =
                                a.roller && a.roller.length
                                    ? a.roller
                                          .map((rolle) => rolle.navn)
                                          .sort()
                                          .join(', ')
                                    : '';
                            b =
                                b.roller && b.roller.length
                                    ? b.roller
                                          .map((rolle) => rolle.navn)
                                          .sort()
                                          .join(', ')
                                    : '';
                            break;

                        case 'sorgGrupper':
                            a = a.sorgGrupper && a.sorgGrupper.length ? a.sorgGrupper[0].tittel : '';
                            b = b.sorgGrupper && b.sorgGrupper.length ? b.sorgGrupper[0].tittel : '';
                            break;

                        default:
                            a = a.id;
                            b = b.id;
                    }

                    if (!isDesc[0]) {
                        return a.localeCompare(b, 'no', { numeric: true, sensitivity: 'base' });
                    } else {
                        return b.localeCompare(a, 'no', { numeric: true, sensitivity: 'base' });
                    }
                });
            }
            this.$emit('sorted', items);
            return items;
        },
    },
};
</script>
<style lang="scss" scoped></style>
