<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="v-dialog--overlay">
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="personer.length > 1 && person.id" text v-on:click="prev">
                <v-icon> mdi-chevron-left </v-icon>
                Forrige
            </v-btn>
            <v-btn v-if="personer.length > 1 && person.id" text v-on:click="next">
                Neste
                <v-icon right> mdi-chevron-right </v-icon>
            </v-btn>
        </v-toolbar>
        <div style="background-color: #fff; position: relative">
            <br />
            <person v-if="person.id" :id="person.id" :role="rolle" v-on:close="close"> </person>
            <new-person v-else :role="rolle" v-on:close="close" :dialog="true"> </new-person>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Person from '@/pages/persons/Person.vue';
import NewPerson from '@/pages/persons/New.vue';

export default {
    name: 'DialogPerson',
    props: {
        value: {
            type: Object,
            required: true,
        },
        personer: {
            type: Array,
            required: true,
        },
        rolle: {
            type: String,
            required: true,
        },
    },
    components: {
        Person,
        NewPerson,
    },
    data() {
        return {
            person: {},
            dialog: false,
            index: -1,
        };
    },
    computed: {
        ...mapGetters('authenticate', ['user']),
        ...mapGetters('api', ['hasUserRole']),
        title: function () {
            if (!this.person || !this.person.id) {
                return 'Opprett';
            } else {
                if (this.hasUserRole('ANSATT')) {
                    return 'Rediger';
                } else {
                    return 'Brukerkort';
                }
            }
        },
    },
    methods: {
        /**
         * edit
         */
        edit: function (index) {
            this.person = this.personer[index];
            this.index = index;
            this.dialog = true;
        },

        /**
         * create
         */
        create: function () {
            this.person = {};
            this.dialog = true;
        },

        /**
         * close
         */
        close: function (arg) {
            this.dialog = false;
            this.$emit('closed', arg);
        },

        /**
         * prev
         */
        prev: async function () {
            if (this.index > 0) {
                const index = this.index - 1;
                await this.edit(index);
            }
        },

        /**
         * next
         */
        next: async function () {
            if (this.index < this.personer.length - 1) {
                const index = this.index + 1;
                await this.edit(index);
            }
        },
    },
};
</script>
<style lang="scss">
.v-dialog__content:has(.v-dialog--overlay) {
    //z-index: 5 !important;
}

.v-dialog--fullscreen {
    background-color: #fff;
}
</style>
